@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: ApercuAktiv;
  src: url('./assets/fonts/ApercuAktiv-Regular.ttf') format('truetype'), url('./assets/fonts/ApercuAktiv-Regular.otf') format('opentype');
}

@font-face {
  font-family: ApercuAktiv;
  src: url('./assets/fonts/ApercuAktiv-Italic.ttf') format('truetype'), url('./assets/fonts/ApercuAktiv-Italic.otf') format('opentype');
  font-style: italic;
}

@font-face {
  font-family: ApercuAktiv;
  src: url('./assets/fonts/ApercuAktiv-Bold.ttf') format('truetype'), url('./assets/fonts/ApercuAktiv-Bold.otf') format('opentype');
  font-weight: bold;
}

@font-face {
  font-family: ApercuAktiv;
  src: url('./assets/fonts/ApercuAktiv-Black.ttf') format('truetype'), url('./assets/fonts/ApercuAktiv-Black.otf') format('opentype');
  font-weight: 800;
}

@font-face {
  font-family: ApercuAktiv;
  src: url('./assets/fonts/ApercuAktiv-BoldItalic.ttf') format('truetype'), url('./assets/fonts/ApercuAktiv-BoldItalic.otf') format('opentype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: ApercuAktiv;
  src: url('./assets/fonts/ApercuAktiv-Thin.ttf') format('truetype'), url('./assets/fonts/ApercuAktiv-Thin.otf') format('opentype');
  font-weight: 100;
}

@font-face {
  font-family: ApercuAktiv;
  src: url('./assets/fonts/ApercuAktiv-ExtraLight.ttf') format('truetype'), url('./assets/fonts/ApercuAktiv-ExtraLight.otf') format('opentype');
  font-weight: 200;
}

@font-face {
  font-family: ApercuAktiv;
  src: url('./assets/fonts/ApercuAktiv-Light.ttf') format('truetype'), url('./assets/fonts/ApercuAktiv-Light.otf') format('opentype');
  font-weight: 300;
}

:root {
  --color-midnight-black: #191E24;
  --color-midnight-dark: #0A0D0F;
  --color-midnight-light: #2E3742;
  --color-midnight-light-menu: #2e3742b5;
  --color-divider: #3B4654;
  --color-disabled: #F9F9F9;
  --color-link-inactive: #e2dede;
  --text-gray-secondary: #8C9BB0;
  --color-blau-neue: #337BE2;
  --color-blue-hover: #1C61C4;
  --color-blue-secondary: #4189EF;
  --color-blue-blockquote: #052e4278;
  --color-error-red: #E25061;
  --color-white-usc: rgba(255, 255, 255, 0.95);
  --color-black-card: #000000e0;
  --color-warning: #E8C11E;
  --color-warning-text: #884514;
  --color-warning-aside: #e8c11e29;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-midnight-black);
  color: var(--color-white-usc);
  font-family: ApercuAktiv;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-white-usc {
  color: #FFFFFF;
  opacity: 0.95;
}

.placeholder-secondary::placeholder {
  color: var(--text-gray-secondary);
  opacity: 1; 
}

.placeholder-secondary:-ms-input-placeholder {
  color: var(--text-gray-secondary);
}

.placeholder-secondary::-ms-input-placeholder {
  color: var(--text-gray-secondary);
}

.absolute-center-vertical {
  top: 50%;
  transform: translateY(-50%); 
}

.sidebarList a, .navbarList a {
  color: var(--color-link-inactive);
}

.sidebarList a[class*="active-link"], .navbarList a[class*="active-link"] {
  color: var(--color-white-usc);
}

.close-svg:hover path {
  fill: var(--color-blau-neue);
}

.main-section-mobile {
  display: none;
}

.responsive-nav {
  z-index: 100;
}

pre {
  z-index: 0;
}

@media (min-width: 360px) {
  .xsm-block {
    display: block;
  }
}

@media (max-width: 480px) {
  .navbar ul li a {
    font-size: 0.88rem;
  }
}

@media (max-width: 1023px) {
  .sidebar {
    padding-top: 1rem;
    padding-left: .7rem;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    background: var(--color-midnight-black);
  }
}

@media (max-width: 1023px) {
  .main-section {
    display: none;
  }

  .main-section-mobile {
    display: block;
  }
}